<template>
   <div class="container-fluid timeline-page">
         <b-row >
            <b-col lg="12" >
               <card class=" card-block card-stretch card-height">
                  <template v-slot:body>
                     <div class="iq-timeline1 m-0 d-flex align-items-center justify-content-between position-relative">
                        <ul class="list-inline p-0 m-0 w-100">
                           <li class="bg-primary rounded">
                              <div class="content">
                                 <div class="timeline-dots new-timeline-dots"></div>
                                 <h6 class="mb-1 text-white">Client Login</h6>
                                 <div class="d-inline-block w-100">
                                    <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                                 </div>
                              </div>
                           </li>
                           <li class="bg-success rounded">
                              <div class="content">
                                 <div class="timeline-dots new-timeline-dots border-success"></div>
                                 <h6 class="mb-1 text-white">Scheduled Maintenance</h6>
                                 <div class="d-inline-block w-100">
                                    <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                                 </div>
                              </div>
                           </li>
                           <li class="bg-primary rounded">
                              <div class="content">
                                 <div class="timeline-dots border-primary"></div>
                                 <h6 class="mb-1 text-white">Client Call</h6>
                                 <div class="d-inline-block w-100">
                                    <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                                 </div>
                              </div>
                           </li>
                           <li class="bg-success rounded">
                              <div class="content">
                                 <div class="timeline-dots border-success"></div>
                                 <h6 class="mb-1 text-white">Mega event</h6>
                                 <div class="d-inline-block w-100">
                                    <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                                 </div>
                              </div>
                           </li>
                           <li class="bg-primary rounded">
                              <div class="content">
                                 <div class="timeline-dots border-primary"></div>
                                 <h6 class="mb-1 text-white">Client Call</h6>
                                 <div class="d-inline-block w-100">
                                    <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                                 </div>
                              </div>
                           </li>
                           <li class="bg-success rounded">
                              <div class="content">
                                 <div class="timeline-dots border-success"></div>
                                 <h6 class="mb-1 text-white">Mega event</h6>
                                 <div class="d-inline-block w-100">
                                    <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                                 </div>
                              </div>
                           </li>
                        </ul>
                     </div>
                  </template>
               </card>
            </b-col>
         </b-row>
      </div>
</template>
<script>
export default {
   name:'Timeline3'
}
</script>